<template>
  <EditorWrapper
    :is_design_mode="isDesignMode"
    class="relative"
    :actions="designActions"
    aut-list
    :definition="definition"
    :context="context"
    @update:definition="$emit('refresh_content', $event)"
    @edit_list="displayListEditDialog = true"
    :class="`behaviour_list ${
      isDesignMode ? 'designMode pa-1' : ''
    } ${displayClasses}`"
    @remove_list="$emit('remove')"
    @edit_permissions="permissionDialogToggle = true"
  >
    <!-- Adding this solely for testing so we can focus on it -->
    <button aut-editor-focus hidden v-if="isDesignMode" />
    <EditListDialog
      v-if="displayListEditDialog"
      :definition.sync="definition"
      @close="displayListEditDialog = false"
    />
    <PermissionEditor
      v-if="permissionDialogToggle"
      :context="context"
      mode="component"
      :definition.sync="definition"
      @close="permissionDialogToggle = false"
    />
    <EditableField
      aut-list-title
      class="behaviour_component_title"
      v-if="displayTitle"
      :design="isDesignMode"
      :text.sync="definition.title"
      :definition="{
        label: 'List Title',
        hint: 'Optional. Leave blank to skip',
      }"
      @update:text="updateNode('title', $event)"
    >
      <ContentTitle
        class="text-left"
        :title="effectiveTitle"
        aut-list-title-value
      />
    </EditableField>
    <!-- 
      NOTE ON CLASSES USED:
      pb-2: Added to ensure gap between search bar/ list-level actions and content below
     -->
    <v-row dense>
      <ListFilter
        v-if="showFilter"
        :filters="filters"
        :criteria="filterData"
        @filter="filterData = $event"
        :display="filterDisplay"
        :context="context"
      />
      <v-col cols="12" :lg="filterWidth">
        <v-row
          dense
          no-gutters
          class="d-flex flex-wrap flex-shrink-1 flex-grow-1 behavior_dont_include_in_email pb-2"
          :justify="`${actionWithSearch ? 'end' : 'start'}`"
          v-if="
            !isDesignMode &&
            ((showSearch && mountSearchHelper) ||
              (actionWithSearch && actions.length))
          "
        >
          <SearchHelper
            v-if="showSearch && mountSearchHelper"
            :criteria="searchData"
            :key="searchHelperKey"
            :width="!actionWithSearch || actions.length == 0 ? '12' : ''"
            :definition="definition"
            @search="searchData = $event"
            :context="context"
            @filter="toggleFilter"
          />
          <v-col cols="12">
            <AlphabeticSearch
              v-if="displayAlphabeticalSearch"
              :criteria="alphabeticSearchData"
              :definition="searchDefinition"
              @search="alphabeticSearchData = $event"
            />
          </v-col>
          <v-col
            cols="12"
            lg="6"
            :class="{ 'd-flex': actionsAttributes.position == 'end' }"
            class="d-sm-flex px-md-auto px-2 justify-end behavior_list_actions"
            style="flex-wrap: wrap"
            v-if="actionWithSearch && actions.length"
          >
            <Field
              class="pl-2 mt-1"
              cols="12"
              lg="4"
              v-for="action in actions"
              :key="`${action.name}-${actionRefreshKey}`"
              :dont_allow_edit="true"
              :definition="action"
              :path="`${action.name}`"
              :context="context"
            />
          </v-col>
        </v-row>
        <LoaderContent
          :value="displayOverlay"
          mode="overlay"
          aut-list-overlay
        />
        <div>
          <EditorActions
            class="pa-1"
            :context="context"
            v-if="showToggle"
            :actions="listEditorActions"
            @toggle_mode="toggleMode"
          />
          <ListTable
            v-if="displayMode == 'table' || isDesignMode"
            :context="context"
            :key="definitionUpdated"
            :results="filteredResults"
            :attributes="displayAttributes"
            :originalResults="results"
            :pagination="pagination"
            :pagination_start="pagination_start"
            :loader="overlay && !displayOverlay"
            :stop_pagination="stop_pagination"
            @refresh_content="$emit('refresh_content', $event)"
            @remove="$emit(`remove`)"
            @remove_action="removeAction($event)"
            @selection_changed="refreshActions"
            @next_page="fetchNextPage"
            @prev_page="fetchPrevPage"
          />
          <ListCards
            v-if="displayMode == 'cards' && !isDesignMode"
            :context="context"
            :attributes="displayAttributes"
            :key="definitionUpdated"
            :pagination="pagination"
            :pagination_start="pagination_start"
            :stop_pagination="stop_pagination"
            :loaderToggle="overlay && initialised"
            :results="filteredResults"
            @next_page="fetchNextPage"
            @prev_page="fetchPrevPage"
          />
          <ListItems
            v-if="displayMode == 'list' && !isDesignMode"
            :context="context"
            :pagination="pagination"
            :key="definitionUpdated"
            :results="filteredResults"
            :attributes="listItemAttributes"
            :stop_pagination="stop_pagination"
            @next_page="fetchNextPage"
            @prev_page="fetchPrevPage"
          />
          <ListSlider
            v-if="displayMode == 'slider' && !isDesignMode"
            :attributes="displayAttributes"
            :context="context"
            :key="definitionUpdated"
            :results="filteredResults"
          />
          <v-row v-if="!actionWithSearch && actions.length && !isDesignMode">
            <v-col
              cols="12"
              class="d-sm-flex justify-end behavior_list_actions"
              style="flex-wrap: wrap"
            >
              <Field
                class="pl-2 mt-1"
                cols="12"
                lg="4"
                v-for="action in actions"
                :key="`${action.name}-${actionRefreshKey}`"
                :dont_allow_edit="true"
                :definition="action"
                :path="`${action.name}`"
                :context="context"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </EditorWrapper>
</template>

<script>
import searchMixin from "@/components/pageContent/List/searchMixin";
import { isChildOf, clone, uniqueID } from "@/util";
import { isEqual, isPlainObject, defaultsDeep, pickBy } from "lodash";
import SearchHelper from "./SearchHelper.vue";
import ListFilter from "./ListFilter.vue";
import EditorWrapper from "@/components/editor/EditorWrapper";
import { componentMixin, componentDesignerMixin } from "@/components/mixin.js";
import { STORE_CONSTS } from "@/components/fields/store.js";
import { isAlphabeticSearch, PERFORM_BULK_OPERATIONS } from "./util";
import ContentTitle from "../ContentTitle.vue";
import factory from "@/components/pageContent/List/pagination/factory";
import { PAGINATION_START } from "@/constants";
import permissionsMixin from "@/components/permissionsMixin.js";

const debug = require("debug")("atman.components.list"); // eslint-disable-line

import ListTable from "./ListTable";
import ListCards from "./ListCards";
import ListItems from "./ListItems";
import ListSlider from "./ListSlider";

export default {
  name: "List",
  components: {
    Field: () => import("@/components/fields/Field"),
    EditorWrapper,
    SearchHelper,
    ListTable,
    ListCards,
    ListItems,
    ListSlider,
    ContentTitle,
    EditListDialog: () =>
      import("@/components/pageContent/List/ListTable/EditListDialog"),
    ListFilter,
    AlphabeticSearch: () => import("./AlphabeticSearch/AlphabeticSearch.vue"),
  },
  mixins: [
    componentMixin,
    componentDesignerMixin,
    searchMixin,
    permissionsMixin,
  ],
  watch: {
    $route() {
      this.fetchQueryParams();
    },
    results() {
      debug(`watch on results triggered`, this.results?.length, this.results);
      factory.reloadEffectiveResults(this);
    },
    searchData(newValue, oldValue) {
      if (isEqual(oldValue, newValue)) {
        debug(`ignoring searchData change`);
        return;
      }
      debug(`searchValue`, "oldValue", oldValue, "newValue", newValue);
      this.updateSearchResult();
    },
    filterData(oldValue, newValue) {
      debug(`filterData`, oldValue, newValue);
      this.updateSearchResult();
    },
    alphabeticSearchData(oldValue, newValue) {
      debug(`alphabetic search data`, oldValue, newValue);
      this.updateSearchResult();
    },
    displayAlphabeticalSearch(isActive) {
      if (!isActive) {
        this.alphabeticSearchData = {};
      }
    },
  },
  data() {
    return {
      overlay: false,
      searchHelperKey: 1,
      displayMode: "cards",
      displayListEditDialog: false,
      results: [],
      filteredResults: [],
      initialised: false,
      searchData: null,
      filterData: null,
      alphabeticSearchData: null,
      mountSearchHelper: false,
      selected: [],
      actionRefreshKey: 1,
      pagination_start: PAGINATION_START,
      stop_pagination: false,
      showFilter: true,
    };
  },
  computed: {
    filterWidth() {
      const filterWidth = (this.filterDisplay?.width || 2) * 1;
      return this.filterDisplay.position == "left"
        ? `${12 - filterWidth}`
        : "12";
    },
    displayOverlay() {
      return this.overlay && this.isFeatureEnabled("list.overlay.enabled");
    },
    paginationCount() {
      return this?.pagination?.count;
    },
    pagination() {
      const result = this?.definition?.apis?.data?.pagination;
      debug(`pagination`, result);
      return result;
    },
    actionWithSearch() {
      const result =
        this?.definition?.display?.attributes?.["actions_position"] != "bottom";
      debug(
        `actionWithSearch`,
        this?.definition?.display?.attributes?.["actions_position"],
        result
      );
      return result;
    },
    hasBulkOperation() {
      return !!this?.definition?.definition?.bulk_operation;
    },
    bulkUpdateFields() {
      return (this?.formFields || []).filter((field) => !!field.bulk_operation);
    },
    actions: {
      get() {
        const component = this;
        const tableDefinition = component?.definition?.definition || {};
        const actions = clone([...(tableDefinition?.actions || [])]);
        if (this.hasBulkOperation) {
          const action = {
            name: PERFORM_BULK_OPERATIONS,
            label: "Bulk Operations",
            _ignore_in_page_editor_: true,
            disabled: !(
              (component.selected?.length || 0) > 0 &&
              component.bulkUpdateFields.length > 0
            ),
            type: "action",
            value: {
              type: "event",
              name: PERFORM_BULK_OPERATIONS,
            },
          };
          actions.push(action);
        }
        if (!this?.selected?.length) {
          actions.forEach((action) => {
            if (action.bulk_operation) {
              action.disabled = true;
            }
          });
        }
        debug(`actions`, actions);
        return actions.map((item) => {
          item.id = item.id || uniqueID();
          return item;
        });
      },
      set(value) {
        this.updateActionsOrder(value);
      },
    },
    showToggle() {
      return (
        !this.isDesignMode && //- NOT in design mode
        this?.listEditorActions.length
      );
    },
    listEditorActions() {
      const result = [];
      const toggles = this?.definition?.display?.attributes?.toggles || [];
      if (toggles.includes("table")) {
        result.push({
          id: "table_mode",
          label: "Table Mode",
          icon: "mdi-table",
          disabled: this.displayMode == "table",
          classes: "pr-1 primary--text",
          event: "toggle_mode",
          param: "table",
        });
      }
      if (toggles.includes("cards")) {
        result.push({
          id: "card_mode",
          label: "Card Mode",
          icon: "mdi-card-account-details",
          disabled: this.displayMode == "cards",
          classes: "pr-1 primary--text",
          event: "toggle_mode",
          param: "cards",
        });
      }

      if (toggles.includes("list")) {
        result.push({
          id: "list_mode",
          label: "List Mode",
          icon: "mdi-format-list-bulleted-square",
          disabled: this.displayMode == "list",
          classes: "pr-1 primary--text",
          event: "toggle_mode",
          param: "list",
        });
      }

      return result;
    },
    hasCards() {
      if (this.definition.definition.mode == "cards") {
        return true;
      }
      const result =
        this.formFields.length == 1 &&
        (this.formFields[0].type == "object" ||
          this.formFields[0].type == "application_menu");

      return result;
    },
    listItemAttributes() {
      const attributes = this.definition?.display?.attributes;
      const result = {
        avatar: attributes?.avatar || "",
        icon: attributes?.icon || "",
      };
      return result;
    },
    effectiveSearchFields() {
      const allFields = this.formFields.map((field) => field);
      let virtualFields = [];
      allFields.forEach((col) => {
        const isGroupedField = col.type == "object" && col.is_container == true;
        if (isGroupedField) {
          virtualFields = [...virtualFields, ...col.fields];
        }
      });
      return [...allFields, ...virtualFields];
    },
    searchDefinition() {
      return this.definition?.definition?.search;
    },
    filters() {
      return this.searchDefinition?.filters?.fields || [];
    },
    filterDisplay() {
      const filterAttributes = this.searchDefinition?.filters || {};
      debug(`filterAttributes`, filterAttributes);
      return filterAttributes;
    },
    displayAlphabeticalSearch() {
      let displayFlag = this.searchDefinition?.alphabetic_search?.display;
      if (displayFlag == "toggle") {
        return this.searchData || Object.keys(this.filterData || {}).length > 0;
      }
      return displayFlag == "yes";
    },
    actionsAttributes() {
      return this.displayAttributes?.actions || {};
    },
  },
  created() {
    this.designActions = [
      {
        id: "edit-list",
        label: "Edit List",
        icon: "mdi-pencil",
        event: "edit_list",
        param: "",
      },
      {
        id: "remove-list",
        label: "Remove List",
        confirmation: "Are you sure you want to continue?",
        icon: "mdi-delete",
        event: "remove_list",
        param: "",
      },
      {
        id: "edit_permissions",
        label: "Change Permissions",
        icon: "mdi-key",
        event: "edit_permissions",
        param: "",
      },
    ];
  },
  async mounted() {
    let mode = this?.definition?.display?.mode || "table";
    /* Added for backward compatibility */
    this.determineTitleDisplay();
    if (this.definition.definition.mode == "cards") {
      mode = "cards";
      this.definition.display = this.definition.display || {};
      this.definition.display.mode = "cards";
    }
    this.displayMode = mode;
    this.fetchQueryParams();
    // Avoid duplicate triggering of fetchData
    await this.fetchData();
  },
  methods: {
    storeData(effectiveResults) {
      const component = this;
      component.$store.commit(
        `${component.context}/${STORE_CONSTS.DATA}`,
        effectiveResults
      );
      component.$store.commit(
        `${component.context}/${STORE_CONSTS.ORIGINAL_DATA}`,
        effectiveResults
      );
    },
    async fetchData() {
      const component = this;
      const methodDebug = debug.extend("fetchData");
      methodDebug(`fetchData`, component.definition);
      const staticData = component.definition?.data || [];
      if (staticData.length) {
        // TODO handle search
        component.storeData(staticData);
        component.results = staticData;
        methodDebug(`Rendering static data`, staticData);
        return;
      }

      component.overlay = true;

      const params = factory.getRuntimeQueryParams(component);

      methodDebug(`getData being invoked with params`, params);
      let fetchedData = [];
      try {
        fetchedData = await component.$store.dispatch(
          `${component.context}/getData`,
          params
        );
        // defensive code to filter out null values in list
        fetchedData = (fetchedData || []).filter((item) => item != null);
        methodDebug(`data fetched:`, fetchedData);
      } catch (e) {
        // store.dispatch("error", "Lorem ipsum");
        console.error(`fetchData failed`, e);
      } finally {
        component.overlay = false;
        let effectiveResults = factory.getEffectiveResults(
          component,
          fetchedData
        );
        effectiveResults = clone(effectiveResults || []);
        methodDebug(`fetchData finally:`, effectiveResults);
        component.storeData(effectiveResults);
        // Update the component
        component.$set(component, "results", effectiveResults);
        methodDebug(`Component results updated`, effectiveResults);
        factory.setPaginationFlags(component, fetchedData);

        component.setTimers();
        component.setupContextWatch(component?.definition?.apis?.data);
      }
    },
    fetchQueryParams() {
      const context = this?.$store?.state?.[this?.context].context;

      let queryParam;
      if (!isChildOf(this.$el, ".behavior_dynamic_page")) {
        queryParam = clone(this.$route?.query || {});
      } else {
        queryParam = clone(context.params || {});
      }
      let searchData = null;
      let filterData = null;
      let alphabeticSearchData = null;
      if (queryParam.search) {
        try {
          searchData = JSON.parse(queryParam.search);
          if (!isPlainObject(searchData)) {
            searchData = `${searchData}`;
          }
        } catch {
          searchData = `${queryParam.search}`;
        }
        if (!isEqual(this.searchData, searchData)) {
          this.searchData = searchData;
        }
      }
      if (queryParam.filter) {
        let data = {};
        try {
          data = JSON.parse(queryParam.filter);
        } catch {
          debug("invalid filter params", queryParam.filter);
        }

        filterData = pickBy(data, (value) => !isAlphabeticSearch(value));

        if (!isEqual(this.filterData, filterData)) {
          this.filterData = filterData;
        }

        alphabeticSearchData = pickBy(data, (value) =>
          isAlphabeticSearch(value)
        );
        if (!isEqual(this.alphabeticSearchData, alphabeticSearchData)) {
          this.alphabeticSearchData = alphabeticSearchData;
        }
      }

      this.mountSearchHelper = true;
    },
    updateQueryParams() {
      let originalQueryParams = clone(this.$route.query || {});
      let updatedQueryParams = clone(originalQueryParams);
      if (isChildOf(this.$el, ".behavior_dynamic_page")) {
        return;
      }
      const searchData = this.searchData;
      const filterData = this.filterData;
      const alphabeticSearchData = this.alphabeticSearchData;
      debug(`searchData`, searchData);
      delete updatedQueryParams.search;
      delete updatedQueryParams.filter;

      if (searchData) {
        if (isPlainObject(searchData)) {
          updatedQueryParams = Object.assign({}, updatedQueryParams, {
            search: JSON.stringify(searchData),
          });
        } else {
          updatedQueryParams.search = searchData;
        }
      }

      let data = {};

      if (isPlainObject(filterData)) {
        data = Object.assign({}, filterData);
      }

      if (isPlainObject(alphabeticSearchData)) {
        data = defaultsDeep({}, alphabeticSearchData, data);
      }

      if (Object.keys(data).length) {
        updatedQueryParams = Object.assign({}, updatedQueryParams, {
          filter: JSON.stringify(data),
        });
      }
      debug(`query Params`, updatedQueryParams);
      if (!isEqual(originalQueryParams, updatedQueryParams)) {
        debug(`Updating url`, originalQueryParams, updatedQueryParams);
        this.$router.replace({ query: updatedQueryParams });
      }
    },
    refreshActions(selected) {
      const component = this;
      component.selected = selected;
      setTimeout(() => {
        component.actionRefreshKey++;
      }, 10);
    },
    toggleMode(params) {
      this.displayMode = params;
    },
    fetchNextPage() {
      this.pagination_start = this.pagination_start + this.paginationCount;
      debug(`fetchNextPage invoked. paginationStart: `, this.pagination_start);
      this.fetchData();
    },
    fetchPrevPage() {
      let start = this.pagination_start - this.paginationCount;
      // Can't go below PAGINATION_START
      start = start <= PAGINATION_START ? PAGINATION_START : start;
      this.pagination_start = start;
      debug(`fetchPrevPage invoked. paginationStart: `, this.pagination_start);
      this.fetchData();
    },
    toggleFilter(event) {
      if (this.filterDisplay?.display == "toggle") {
        if (!event) {
          this.filterData = {};
        }
        this.showFilter = event;
      }
    },
    updateSearchResult() {
      this.updateQueryParams();
      this.pagination_start = PAGINATION_START;
      factory.handleSearch(this);
    },
  },
};
</script>
