<template>
  <v-container class="pa-0 behaviour_list_cards_container aut-list-cards">
    <v-row dense v-if="isApplicationMenu" :class="defaultJustification">
      <Field
        aut-list-card
        v-for="(card, index) in menusWithPermissions"
        :key="`${card.menu.id}-${index}`"
        :definition="card.definition"
        :path="`[${index}]`"
        :index="index"
        :context="context"
      />
    </v-row>
    <div v-else-if="formDefinition && fieldDefinition">
      <v-row
        dense
        :class="cardAttributes.justify || defaultJustification"
        v-if="!loaderToggle && !results.length && showNoDataMessage"
      >
        <v-col class="text-center" aut-no-data-msg>
          {{ noDataMessage }}
        </v-col>
      </v-row>
      <ListCardContainer
        :draggable="isList"
        :list="paginatedResults"
        @update_list="handleOrderChange"
      >
        <v-col
          v-for="(item, i) in paginatedResults"
          :key="`${item.id}-${i}`"
          class="behavior_list_card_container"
          :class="itemClasses"
          aut-list-card
          v-intersect.quiet="
            isInfiniteScroll && i == paginationCount - 3 ? loadNextPage : false
          "
          v-bind="widthAttributes"
        >
          <div
            class="list_card_wrapper behaviour_list_item"
            @click.stop="navigateToAction($event)"
            v-ripple="!!navigationAction"
          >
            <ListCard
              :is_static="cardAttributes.is_static"
              :form_definition="formDefinition"
              :field_definition="fieldDefinition"
              :context="context"
              :index="i"
              :item="item"
            />
          </div>
        </v-col>
      </ListCardContainer>
      <v-row dense v-if="footers.length">
        <v-col cols="12">
          <Field
            v-for="(footer, i) in footers"
            :key="`footer-${i}`"
            aut-cards-footer
            :definition="footer"
            :context="context"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <ListPaginator
          v-model="page"
          :stop_pagination="stop_pagination"
          :paginatorToggle="paginatorToggle"
          :pagination="pagination"
          :loaderToggle="loaderToggle"
          @next="loadNextPage"
          @previous="loadPrevPage"
        />
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { pick } from "lodash";
import { clone, removeArrayPlaceholder, checkPermissions } from "@/util";
import { componentDesignerMixin } from "@/components/mixin.js";
import paginationMixin from "@/components/pageContent/List/mixin.js";
import listItemMixin from "../listItemMixin";
import ListCard from "./ListCard.vue";
import { STORE_CONSTS } from "@/components/fields/store";

import ListCardContainer from "./ListCardContainer.vue";

const debug = require("debug")("atman.components.list.list_cards"); // eslint-disable-line

const FIRST = 0;

export default {
  name: "ListCards",
  mixins: [componentDesignerMixin, paginationMixin, listItemMixin],
  components: {
    ListCard,
    ListCardContainer,
    Field: () => import("@/components/fields/Field"),
  },
  props: {
    attributes: {},
  },
  data() {
    const contextObject = this.$store?.state[this.context]?.context || {};
    return {
      cardClasses: "",
      itemClasses: "",
      contextObject,
      fieldDefinition: null,
      formDefinition: null,
      widthAttributes: {},
    };
  },
  computed: {
    menusWithPermissions() {
      const component = this;
      let filteredMenus = component.checkPermissions(this.results);
      return filteredMenus.map((menu) => {
        return {
          menu,
          definition: this.getDefinition(this.formFields[0], menu),
        };
      });
    },
    isList() {
      return this.definition?.apis?.data?.params?.query == "LIST_NODES";
    },
    showNoDataMessage() {
      const hideMessage =
        this.definition?.display?.attributes?.no_data?.hide_message;
      return typeof hideMessage == "undefined" || hideMessage === false;
    },
    noDataMessage() {
      return (
        this.definition?.display?.attributes?.no_data?.message ||
        "No Data Available"
      );
    },
    cardAttributes() {
      return this.definition?.display?.attributes?.cards || {};
    },
    isApplicationMenu() {
      return (
        this.formFields.length == 1 &&
        this.formFields[0]?.type == "application_menu"
      );
    },
    footers() {
      const result = this.definition?.definition?.footers || [];
      debug(`footers`, result);
      return result;
    },
  },
  watch: {
    cardAttributes: {
      deep: true,
      handler() {
        this.setCardContainerWidth();
      },
    },
  },
  mounted() {
    this.deriveCardWidth();
    this.setCardContainerWidth();
    // ORDER IS important
    this.deriveClasses();
    this.deriveFieldDefinition();
    this.deriveFormDefinition();
    debug(`In mounted of ListCards`, this.results);
    this.resetPaginatedResults();
  },
  methods: {
    getDefinition(definition, card) {
      let output = clone(definition);
      debug(`card`, card, definition);
      if (card.disabled) {
        delete output.click_action;
      }
      return output;
    },
    checkPermissions(list) {
      const component = this;
      let output = checkPermissions(list);
      component.$store.commit(
        `${component.context}/${STORE_CONSTS.DATA}`,
        clone(output)
      );
      debug(`menus`, output);
      return output;
    },
    handleOrderChange(evt, list) {
      // const list = this.paginatedResults;
      const item = list[evt.oldIndex];
      const originalList = this.paginatedResults;
      debug(
        "item moved was",
        item,
        `moved from: [${evt.oldIndex}] to [${evt.newIndex}]`
      );
      let api = this.definition?.apis?.data;
      if (!api) {
        debug(`no data api defined. Skipping order update`);
        return;
      }
      api = pick(api, ["url", "conditions"]);
      api.type = "post";
      api.url = `${api.url}/${item.id}`;
      api.params = { data: {} };
      let payload = { actionDefinition: { value: api } };
      if (evt.newIndex == FIRST) {
        debug(`IN FIRST FLOW`);
        const itemAfter = originalList[evt.newIndex];
        payload.actionDefinition.value.params.action = "move_before";
        payload.actionDefinition.value.params.node = itemAfter.id;
        debug("item after is", itemAfter);
      } else {
        debug(`IN OTHER INDEX FLOW`);
        const itemBefore = originalList[evt.newIndex];
        payload.actionDefinition.value.params.action = "move_after";
        payload.actionDefinition.value.params.node = itemBefore.id;
        debug("item before is", itemBefore);
      }
      debug(`payload`, payload);
      return this.$store.dispatch(`${this.context}/triggerAction`, payload);
    },

    deriveFormDefinition() {
      const fields = clone(this.formFields || []).map((field) => {
        field.display = field.display || {};
        field.display.width = field.display.width || "12";
        return field;
      });
      let result = {
        type: "form",
        definition: {
          fields,
        },
        display: {
          attributes: this.cardAttributes || {},
          classes: this.cardAttributes?.classes || [],
        },
      };
      this.formDefinition = JSON.parse(
        removeArrayPlaceholder(JSON.stringify(result))
      );
    },
    deriveFieldDefinition() {
      // const cardAttributes = this.definition?.display?.attributes?.cards || {};
      const fields = clone(this.formFields || []).map((field) => {
        field.display = field.display || {};
        (field.is_static = true),
          (field.display.width = field.display.width || "12");

        return field;
      });
      let result = {
        is_container: true,
        type: "object",
        display: {
          attributes: {
            behave_as_form: true,
          },
        },
        fields,
        name: "list_card_field",
      };
      debug(`fieldDefinition`, result);
      this.fieldDefinition = result;
    },
    deriveClasses() {
      let result = "px-md-auto px-2";
      if (this.cardAttributes.card_background)
        result += ` card_gradient_background `;
      if (this.navigationAction) {
        result += " behavior_clickable ";
      }
      this.itemClasses = result;
    },
    deriveCardWidth() {
      debug(`in deriveCardWidth`, this.definition);
      const defaultWidths = {
        cols: "12",
        sm: "6",
        md: "4",
        lg: "3",
      };

      const width = this.isSmallScreen ? "12" : this.cardAttributes.width;

      if (!width || (typeof width != "string" && typeof width != "number")) {
        this.widthAttributes = defaultWidths;
        return;
      }

      const cardWidth = width * 1;
      if (!isNaN(cardWidth)) {
        this.widthAttributes = {
          cols: cardWidth,
        };
        return;
      }

      this.widthAttributes = {};
      const currentWidth = this.$el.style.getPropertyValue("--container-width");
      if (currentWidth != width) {
        this.$el.style.setProperty("--container-width", width);
        this.setCardContainerWidth(width);
      }
    },
    setCardContainerWidth(min_width) {
      const root = document.querySelector(":root");
      if (min_width) {
        root.style.setProperty("--container-min-width", min_width);
        return;
      }

      const minWidth = this.cardAttributes?.container_min_width * 1;
      const containerMinWidth = `${minWidth}px`;

      const currentWidth = root.style.getPropertyValue("--container-min-width");

      if (isNaN(minWidth) || currentWidth == containerMinWidth) {
        return;
      }

      root.style.setProperty("--container-min-width", containerMinWidth);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  /* Ensures that when more than one page is pinned, the cards do not become too thin */
  .behavior_list_card_container {
    min-width: var(--container-min-width, 200px) !important;
    width: var(--container-width);
  }
}
.behavior_list_card {
  ::v-deep {
    .behavior_form_field {
      padding: 2px !important;

      // Dropdowns styled slightly differently when in a card
      .behavior_dropdown_container {
        margin-top: 16px;
        font-size: small;
      }

      // Position Actions at the top of the card
      .behavior_atman_actions {
        .behavior_action_icon {
          font-size: large;
        }
      }
    }
    .behaviour_address_line {
      min-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<style lang="scss">
.theme--light {
  .behavior_list_card {
    &.behavior_hover_background {
      &:hover {
        background-color: map-get($grey, "lighten-1") !important;
      }
    }
    &.behavior_hover_border {
      &:hover {
        border: 1px solid var(--v-secondary-lighten5) !important;
      }
    }
    &.behavior_card_border {
      border: 1px solid map-get($grey, "lighten-1") !important;
    }
  }
  .card_gradient_background {
    &.behavior_list_card_container {
      .behavior_list_card {
        &:not(:hover) {
          background: transparent
            linear-gradient(to top left, #bef3d6 0%, #f0f9a7 48%);
        }
        &:hover {
          background-color: map-get($grey, "lighten-1") !important;
        }
      }
      &:nth-child(2n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(to top left, #fbba72 0%, #faeba9 48%);
          }
        }
      }
      &:nth-child(3n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(to top left, #bcd979 0%, #eeedc0 48%);
          }
        }
      }
      &:nth-child(4n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(to top left, #f0efa7 0%, #fcd59c 48%) !important;
          }
        }
      }
      &:nth-child(5n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(to top left, #f7e9e8 0%, #e9f0aa 48%);
          }
        }
      }
      &:nth-child(6n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(to top left, #ffa585 0%, #ffeda0 48%) !important;
          }
        }
      }
    }
  }
}
.theme--dark {
  .behavior_list_card {
    &.behavior_hover_background {
      &:hover {
        background-color: #444 !important;
      }
    }
    &.behavior_hover_border {
      &:hover {
        border: 1px solid var(--v-secondary-lighten1) !important;
      }
    }
    &.behavior_card_border {
      border: 1px solid map-get($grey, "lighten-3") !important;
    }
  }
  .card_gradient_background {
    &.behavior_list_card_container {
      .behavior_list_card {
        &:not(:hover) {
          background: transparent
            linear-gradient(302deg, #6d6875 0%, #2d243c 100%);
        }
        &:hover {
          background-color: #444 !important;
        }
      }
      &:nth-child(2n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(302deg, #596f48 0%, #2d243c 100%);
          }
        }
      }
      &:nth-child(3n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(302deg, #183d69 0%, #2d243c 100%);
          }
        }
      }
      &:nth-child(4n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(302deg, #132a13 0%, #2d243c 100%);
          }
        }
      }
      &:nth-child(5n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(302deg, #1d2d44 0%, #2d243c 100%);
          }
        }
      }
      &:nth-child(6n) {
        .behavior_list_card {
          &:not(:hover) {
            background: transparent
              linear-gradient(302deg, #774936 0%, #2d243c 100%);
          }
        }
      }
    }
  }
}

// ensures listcards have same height
.behavior_list_card_container {
  .list_card_wrapper {
    height: 100%;
    .behavior_list_card {
      height: 100%;
    }
  }
}
</style>
