<template>
  <v-card
    class="behaviour_atman_component"
    v-if="hasPermissions"
    :class="displayClasses"
    v-bind="displayAttributes"
  >
    <div>
      <component
        class="behavior_layout_container"
        v-if="storeKey && storeIsReady && currentStyleComponent"
        v-bind:is="currentStyleComponent"
        :layout="componentLayout"
        :context="storeKey"
        :render_key="refreshContent"
      >
        <component
          :class="contentClasses"
          :aut-type="derivedDefinition && derivedDefinition.type"
          :default_title="default_title"
          v-bind:is="currentComponent"
          v-if="currentComponent && storeIsReady && shouldDisplay()"
          :context="storeKey"
          :displayAttributes="displayAttributes"
          :displayClasses="displayClasses"
          @update:definition="updateDefinition"
          @update:permissions="updatePermission"
          @refresh_content="forceRefresh"
          @remove="$emit('remove')"
          :key="refreshContent"
          @component_title="$emit('component_title', $event)"
        ></component>
      </component>
      <component
        :aut-type="derivedDefinition && derivedDefinition.type"
        :default_title="default_title"
        v-bind:is="currentComponent"
        v-else-if="
          !currentStyleComponent &&
          currentComponent &&
          storeIsReady &&
          shouldDisplay()
        "
        :context="storeKey"
        :displayAttributes="displayAttributes"
        @update:definition="updateDefinition"
        @update:permissions="updatePermission"
        @refresh_content="forceRefresh"
        @remove="$emit('remove')"
        @component_title="$emit('component_title', $event)"
        :key="refreshContent"
      ></component>
      <slot></slot>
    </div>
  </v-card>
</template>
<script>
import Form from "@/components/pageContent/Form";
import List from "@/components/pageContent/List";
import formDefinition from "@/components/pageContent/Form/definition";
import listDefinition from "@/components/pageContent/List/definition";
import Layouts from "@/components/pageContent/Layouts";
import { store, STORE_CONSTS } from "@/components/fields/store";
import {
  clone,
  uniqueID,
  registerToEvents,
  evaluateConditions,
  mergeClasses,
} from "@/util.js";
import { omit, isEqual, snakeCase, set, defaultsDeep } from "lodash";

const debug = require("debug")("atman.components.atman"); // eslint-disable-line

const isValid = (data) => {
  if (!data) {
    return false;
  }
  return (
    (typeof data == "object" && Object.keys(data).length > 0) ||
    (Array.isArray(data) && data.length > 0)
  );
};

export default {
  name: "AtmanComponent",
  components: {
    Form,
    List,
  },
  data() {
    return {
      derivedDefinition: clone(this.definition),
      storeIsReady: false,
      storeKey: "",
      currentComponent: null,
      currentStyleComponent: null,
      refreshContent: 1,
    };
  },
  props: {
    context: {
      type: Object,
    },
    default_title: {
      type: String,
    },
    definition: {
      type: Object,
    },
    data: {},
    path: {
      type: String,
    },
    design: {
      type: Boolean,
    },
  },
  computed: {
    hasPermissions() {
      return this.checkPermissions();
    },
    contentClasses() {
      const content = this.componentLayout?.content;
      return content?.classes || [];
    },
    displayAttributes() {
      const type = this.derivedDefinition?.type;
      if (!type) {
        return {};
      }
      let defaultAttributes = {};
      let defaultDefinition = {};
      if (type == "form") {
        defaultDefinition = formDefinition;
      } else if (type == "list") {
        defaultDefinition = listDefinition;
      }

      (defaultDefinition.attributes || []).forEach((attribute) => {
        set(defaultAttributes, attribute.name, attribute.value);
      });

      const result = defaultsDeep(
        this.definition?.display?.attributes || {},
        defaultAttributes
      );

      return omit(result || {}, "width");
    },
    displayClasses() {
      const type = this.derivedDefinition?.type;
      if (!type) {
        return {};
      }

      let defaultDefinition = {};
      if (type == "form") {
        defaultDefinition = formDefinition;
      } else if (type == "list") {
        defaultDefinition = listDefinition;
      }
      const result = mergeClasses({
        componentDefinition: defaultDefinition,
        runtimeDefinition: this.derivedDefinition,
      });

      return (result || []).join(" ");
    },
    componentLayout() {
      const layout = this.derivedDefinition?.display?.attributes?.layout || {};
      return layout;
    },
  },
  created() {
    debug(
      "in created of Atman component",
      this.context,
      this.derivedDefinition,
      this.data
    );
  },
  watch: {
    definition() {
      this.derivedDefinition = clone(this.definition);
    },
  },
  async mounted() {
    debug(
      "in mounted of Atman component",
      this.context,
      this.derivedDefinition,
      this.data
    );
    await this.createStore();
    if (!this.hasPermissions) {
      return;
    }
    this.deriveComponent();
    this.deriveStyleComponent();
    this.setHighlight();
  },
  methods: {
    checkPermissions() {
      const methodDebug = debug.extend("checkPermissions");
      const permissionsObject = this.derivedDefinition._permissions;
      if (!permissionsObject?.enabled) {
        methodDebug(
          `No permission configured or permissions check are disabled`
        );
        return true;
      }
      const permissionName = permissionsObject?.permission?.value;
      if (!permissionName) {
        methodDebug(`No Permission name available`);
        return true;
      }
      const isAllowed =
        this.$store.getters[`user/canPerformAction`](permissionName);

      if (isAllowed) {
        methodDebug(`User can perform action [${permissionName}]`);
        return true;
      }
      return false;
    },
    highlightColor() {
      const radiusColor =
        this.derivedDefinition?.display?.attributes?.highlight?.color;
      if (radiusColor == "primary") {
        return "var(--v-primary-base)";
      }
      if (radiusColor == "secondary") {
        return "var(--v-secondary-base)";
      }
      return radiusColor;
    },
    setHighlight() {
      const highlight = this.derivedDefinition?.display?.attributes?.highlight;

      if (highlight) {
        const position = highlight?.position;
        const color = this.highlightColor();
        const radius = highlight?.radius;
        this.$el.style.setProperty(
          `border-${position}`,
          `${radius} solid ${color}`
        );
      }
    },
    registerStore() {
      const component = this;
      // Create a dynamic store for use with this component
      let name = `${
        component.derivedDefinition.id ||
        snakeCase(component.derivedDefinition.title)
      }`;
      const storeKey = (component.storeKey = `${
        component.derivedDefinition.type
      }_${name}_${uniqueID()}`);
      component._createStore(storeKey, store);
    },
    initialiseStore() {
      const component = this;
      const storeKey = component.storeKey;
      component.$store.commit(`${storeKey}/${STORE_CONSTS.ID}`, storeKey);
      component.$store.commit(
        `${storeKey}/${STORE_CONSTS.CONTEXT}`,
        component.context
      );
      if (component.design) {
        component.$store.commit(
          `${storeKey}/${STORE_CONSTS.DESIGN}`,
          component.design
        );
      }
      const data = component.data || component.derivedDefinition.data;
      if (isValid(data)) {
        component.$store.commit(
          `${storeKey}/${STORE_CONSTS.DATA}`,
          clone(data)
        );
      }
    },

    setupSubscriptions() {
      const component = this;
      const storeKey = component.storeKey;
      component._subscribe(
        component.$store.subscribe((mutation) => {
          if (
            mutation.type == `${storeKey}/${STORE_CONSTS.DATA}` ||
            mutation.type == `${storeKey}/${STORE_CONSTS.FIELD}`
          ) {
            if (
              !component.$store.state[storeKey].data ||
              isEqual(component.data, component.$store.state[storeKey].data)
            ) {
              return;
            }
            const updatedData = component.$store.state[storeKey].data;
            debug(`Emitting update in Atman`, updatedData);
            component.$emit("update-data", clone(updatedData));
          }
        })
      );
    },
    async createStore() {
      const component = this;
      component.registerStore();
      component.initialiseStore();
      this.derivedDefinition = clone(this.definition);
      component.updateDefinition(component.derivedDefinition);
      component.setupSubscriptions();
      component.storeIsReady = true;
    },
    deriveComponent() {
      switch (this.derivedDefinition.type) {
        case "form":
          this.currentComponent = Form;
          break;
        case "list":
          this.currentComponent = List;
          break;
        default:
          console.error(`Unsupported content type`);
      }

      this.registerEventSubscribers();
    },
    deriveStyleComponent() {
      if (!this.componentLayout) {
        return;
      }
      this.currentStyleComponent = Layouts[this.componentLayout?.name];
    },
    registerEventSubscribers() {
      debug(`In registerEventSubscribers`);
      const component = this;
      const events = this?.definition?.definition?.events || [];
      if (!events.length) {
        return;
      }
      const callBackFn = (triggeredEvent, payload) => {
        const payloadValue = payload.value;
        (triggeredEvent.params || []).forEach((param) => {
          debug(`Updating key`, param.target, payloadValue[param.source]);
          this.$set(
            component.context,
            param.target,
            payloadValue[param.source]
          );
          debug(`Updated context for page`, component.context);
        });
        component.refreshContent++;
      };
      debug(`In registerEventSubscribers`, events);
      component._subscribe(
        registerToEvents({
          /* id: component.storeKey, 
        DONT PASS ID blindly here. This component typically listens to evens in other pages */
          $store: component.$store,
          events,
          callBackFn,
        })
      );
    },
    shouldDisplay() {
      const component = this;
      const conditions = component.derivedDefinition?.display?.conditions;

      const outcome = evaluateConditions(conditions, {
        _context: component.context,
      });

      return outcome ? outcome.result : true;
    },
    forceRefresh(definition) {
      if (definition) {
        this.updateDefinition(definition);
      }
      this.refreshContent++;
    },
    updatePermission(definition) {
      this.updateDefinition(definition);
      // this.$store.dispatch(`${this.storeKey}/updatePermissions`, definition);
      this.$emit(`update:permissions`, definition);
    },
    updateDefinition(definition) {
      const component = this;
      const input = clone(definition);

      debug(`updateDefinition invoked with`, input);
      // Update local store
      if (!component.$store.hasModule(component.storeKey)) {
        return;
      }

      component.$store.commit(
        `${component.storeKey}/${STORE_CONSTS.DEFINITION}`,
        input
      );
      component.$emit(`update:definition`, clone(input));
    },
  },
};
</script>
