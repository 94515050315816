<template>
  <v-col cols="12">
    <div v-if="!loaderToggle && isPaginated && paginatorToggle">
      <v-pagination
        aut-page-navigator
        v-model="page"
        :length="numberOfPages"
        v-on="$listeners"
      ></v-pagination>
    </div>
  </v-col>
</template>
<script>
const debug = require('debug')('atman.components.list.list_paginator')  // eslint-disable-line
export default {
  name: "ListPaginator",
  props: {
    pagination: {
      type: Object,
    },
    loaderToggle: {
      type: Boolean,
    },
    paginatorToggle: {
      type: Boolean,
    },
    value: {
      type: Number,
    },
    stop_pagination: {
      type: Boolean,
    },
  },
  data() {
    return {
      page: this.value || 1,
    };
  },
  computed: {
    numberOfPages() {
      let result = this.value + 1;
      if (this.stop_pagination) {
        result = this.value;
      }
      debug(
        `stop_pagination: [${this.stop_pagination}], value: [${this.value}], numberOfPages: [${result}]`
      );
      return result;
    },
    isInfiniteScroll() {
      return this.pagination.type == "infinite_scroll";
    },
    isPaginated() {
      return this.pagination.type == "paginated";
    },
  },
  methods: {
    loadNextPage() {
      debug(`loadNextPage`, this.page, this.value);
    },
    loadPrevPage() {
      debug(`loadPrevPage`, this.page, this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-pagination__item,
  .v-pagination__more {
    display: none;
  }
}
</style>
